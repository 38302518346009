import React, { useRef, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Routing } from './routing'

function App({})
{
    return <Routing/>
}

(function() {
    let el = document.getElementById('root');
    createRoot(el).render(<App frmid={el.getAttribute('data-form')} element={el}/>);
})();
