import React, { useRef, useState, useEffect } from 'react';
import { makeid, getCookieValue } from '../utils';
import { ListGroup, Form, Button, Tab, Tabs, Accordion, Dropdown } from 'react-bootstrap';
import { LinkToggle, DropSearchMenu } from '../dropdownwithmenu';
import { IconBxChevronRight, IconBxChevronLeft, IconOpenInNewWindow } from '../icons'
import * as inputs from '../inputs';

const alts = [
    'TextInput',
    'NumberInput',
    'SingleChoice',
    'NumberChoice',
    'MultiChoice',
    'MultilineInput',
    'DateInput',
    'DropdownInput',
    'ContactInput',
    'DateDropdown',
    'YesNoChoice',
    'BoxChoice',
    'AddressInput',
    'PhoneInput',
    'EmailInput',
    'NameInput',
    'FileUpload',
    'CountrySelect',
    'ApptInput',
    'RangeSlider',
    'CurrencyInput',
    'SignatureInput',
    'ChoiceMatrix',
    'CompanyInput',
]

const fieldMap = {
    'singleLineText': 'TextInput',
    'multilineText': 'MultilineInput',
    'barcode': 'TextInput',
    'number': 'NumberInput',
    'currency': 'CurrencyInput',
    'singleSelect': 'DropdownInput',
    'multipleSelects': 'MultiChoice',
    'url': 'TextInput',
    'email': 'EmailInput',
    'date': 'DateDropdown',
    'dateTime': 'DateInput',
    'multipleAttachments': 'FileUpload',
    'checkbox': 'YesNoChoice',
    'phoneNumber': 'PhoneInput',
    'percent': 'RangeSlider',
    'rating': 'NumberChoice',
}

const inputToStep = {
    'TextInput': inputs.textinput,
    'NumberInput': inputs.numberinput,
    'SingleChoice': inputs.singlechoice,
    'NumberChoice': inputs.numberchoice,
    'MultiChoice': inputs.multichoice,
    'MultilineInput': inputs.multilineinput,
    'DateInput': inputs.dateinput,
    'DropdownInput': inputs.dropdowninput,
    'ContactInput': inputs.contactinput,
    'DateDropdown': inputs.datedropdown,
    'YesNoChoice': inputs.yesnochoice,
    'BoxChoice': inputs.boxchoice,
    'AddressInput': inputs.addressinput,
    'PhoneInput': inputs.phoneinput,
    'EmailInput': inputs.emailinput,
    'NameInput': inputs.nameinput,
    'FileUpload': inputs.fileupload,
    'CountrySelect': inputs.countryselect,
    'ApptInput': inputs.apptinput,
    'RangeSlider': inputs.rangeslider,
    'CurrencyInput': inputs.currencyinput,
    'SignatureInput': inputs.signatureinput,
    'ChoiceMatrix': inputs.choicematrix,
    'CompanyInput': inputs.companyinput,
}

const ignored = new Set(['multipleRecordLinks',
			 'multipleLookupValues',
			 'rollup',
			 'formula',
			 'singleCollaborator',
			 'aiText',
			 'count',
			 'createdBy',
			 'createdTime',
			 'lastModifiedBy',
			 'externalSyncSource',])

function ShowField({field, allFieldsRequired, setAllFieldsRequired, fieldOptions, setFieldOptions})
{
    if ( ! field ) return
    const [valueRequired, setValueRequired] = useState(true)
    useEffect(() => {
	setFieldOptions(prev => ({...prev,
				  [field.id]: { label: field.name,
						name: field.name,
						selected: field.type in fieldMap ? fieldMap[field.type] : '' }}))
    }, [field])
    useEffect(() => {
	if ( allFieldsRequired ) setValueRequired(true)
    }, [allFieldsRequired])
    return field.id in fieldOptions ? (
	<>
	    <div class="d-flex justify-content-between align-items-center mb-2">
		<h4 class="w-100">{field.name}</h4>
		<select class="form-select"
			value={fieldOptions[field.id].selected}
			onChange={ev => {
			    setFieldOptions(prev => ({...prev,
						      [field.id]: {...prev[field.id],
								   selected: ev.target.value}}))
			}}>
		    <option value="">-- Select --</option>
		    {alts.map((alt, ai) => <option key={ai}>{alt}</option>)}
		</select>
	    </div>
	    <div class="mb-2">
		<label class="w-100 form-label">
		    Field Label
		    <span class="ms-2 text-info text-italic" style={{fontSize: '0.8rem'}}>{field.type}</span>
		</label>
		<input type="text"
		       class="form-control"
		       value={fieldOptions[field.id].label}
		       onChange={ev => {
			   setFieldOptions(prev => ({...prev,
						     [field.id]: {...prev[field.id],
								  label: ev.target.value}}))
		       }}/>
	    </div>
	    <div class="mb-2 d-flex">
		<Form.Switch id={field.id + "-requiredCheckbox"}
			     checked={valueRequired}
			     className="d-flex align-items-center"
			     onChange={ev => {
				 setValueRequired(ev.target.checked)
				 if ( ! ev.target.checked ) setAllFieldsRequired(false)
			     }}/>
		<label htmlFor={field.id + "-requiredCheckbox"}
		       className="w-100">
		    Value is required
		</label>
	    </div>
	    {'options' in field && 'choices' in field.options && (
		<>
		    <div class="mb-2">
			<select class="form-select"
				multiple={field.type === 'multipleSelects'}>
			    <option value="">-- Select --</option>
			    {field.options.choices.map((choice, ci) => (
				<option value={choice.id}>{choice.name}</option>
			    ))}
			</select>
		    </div>
		</>
	    )}
	</>
    ) : (
	<div class="ms-2 spinner-border spinner-border-sm text-primary" role="status">
	    <span class="visually-hidden">Loading...</span>
	</div>
    )
}

function makeIntegration(baseId, tableId, airFields, steps)
{
    return {
	"type": "airtable",
	"baseId": baseId,
	"mapping": steps.map((step, si) => ({
	    frmfield: step.dataFields[0].id,
	    airfield: airFields[si].id
	})),
	"tableId": tableId,
	"updateEvent": "form-completion",
	"integrationId": makeid(8)
    }
}

export function FormBuilder({baseId, tableId, tables})
{
    if ( ! tableId ) return
    const [table, setTable] = useState(null),
	  [fields, setFields] = useState([]),
	  [allFieldsRequired, setAllFieldsRequired] = useState(false),
	  [fieldOptions, setFieldOptions] = useState({}),
	  [disableBtn, setDisableBtn] = useState(false),
	  createForm = ev => {
	      setDisableBtn(true)
	      console.log('fields: %O, fieldOptions = %O', fields, fieldOptions)
	      let frm = {
		  'title': table.name,
		  'integrations': null,
		  'options': {
		      'cookieConsent': true,
		      'desktopLayout': 'fullscreen',
		      'mobileLayout': 'fullscreen',
		  },
		  'steps': [{
		      'btnColor': "chocolate",
		      'btnLabel': "Next Step",
		      'displayStr': "Welcome Step",
		      'fieldDescr': table.name,
		      'fieldLabel': table.name,
		      'icon': "diamond",
		      'mainImage': null,
		      'mainImageName': null,
		      'stepId': makeid(8),
		      'type': "WelcomeStep"
		  }, {
		      "url": "https://teramine.com",
		      "icon":"done_all",
		      "type":"ThankyouStep",
		      "stepId":makeid(8),
		      "showUrl":true,
		      "btnColor":"darkmagenta",
		      "urlLabel": "Click here to continue",
		      "displayStr":"Thank You",
		      "headerLabel":"Thank You",
		      "showSummary":false,
		      "summaryFormat":"",
		      "subheaderLabel": 'Thank you'
		  }]
	      }
	      const steps = fields.map((field, fi) => {
		  const question = {question: fieldOptions[field.id].label}
		  if ( 'options' in field && 'choices' in field.options )
		      question.options = field.options.choices.map(choice => choice.name)
		  return inputToStep[fieldOptions[field.id].selected](question)
	      })
	      frm.steps.splice(frm.steps.length - 1, 0, ...steps)
	      frm.integrations = [makeIntegration(baseId, tableId, fields, steps)]
	      console.log('frm = %O', frm)
	      fetch('/api/createform/', {
		  method: 'POST',
		  body: JSON.stringify(frm)
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('response from createform: %O', json)
		      setDisableBtn(false)
		  })
		  .catch(r => {
		      setDisableBtn(false)
		      console.error('error in operation: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		  })
	  }
    useEffect(() => {
	if ( ! tableId ) return
	const table = tables.find(t => t.id === tableId)
	setTable(table)
	setFields(prev => [...table.fields.filter(field => !ignored.has(field.type))])
    }, [tableId])
    return table ? (
	<>
	    <div class="mb-2 d-flex justify-content-between align-items-center">
		<h3 class="w-100">{table.name}</h3>
		<div class="mb-2 d-flex w-50">
		    <Form.Switch id="allFieldsRequiredCheckbox"
				 checked={allFieldsRequired}
				 className="d-flex align-items-center"
				 onChange={ev => setAllFieldsRequired(ev.target.checked)}/>
		    <label htmlFor="allFieldsRequiredCheckbox"
			   className="w-100">
			All fields required
		    </label>
		</div>
	    </div>
	    <ListGroup className="mb-3">
		{fields.map((field, fi) => (
		    <ListGroup.Item key={fi}><ShowField field={field}
							allFieldsRequired={allFieldsRequired}
							setAllFieldsRequired={setAllFieldsRequired}
							fieldOptions={fieldOptions}
							setFieldOptions={setFieldOptions}/></ListGroup.Item>
		))}
	    </ListGroup>
	    <div class="d-flex justify-content-end">
		<button type="button"
			class="btn btn-primary"
			onClick={createForm}
			disabled={disableBtn}>Create Form</button>
	    </div>
	</>
    ) : (
	<div class="ms-2 spinner-border spinner-border-sm text-primary" role="status">
	    <span class="visually-hidden">Loading...</span>
	</div>
    )
}
