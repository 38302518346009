import React, { useRef, useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Tooltip } from 'react-tooltip';
import { Carousel, Form, Button, Modal } from 'react-bootstrap';
import { IconSearch } from './icons'
import './prompt.css'
import Fuse from 'fuse.js'

function CarouselImage({title, text})
{
    return (
	<svg
	    className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
	    width="100%"
	    height="100%"
	    xmlns="http://www.w3.org/2000/svg"
	    role="img"
	    aria-label={`${title}: ${text}`}
	    preserveAspectRatio="xMidYMid slice"
	    focusable="false">
	    <title>{title}</title>
	    <rect width="100%" height="100%" fill="#888" />
	    {text && (
		<text x="50%" y="50%" fill="#fff" dy=".3em">
		    {text}
		</text>
	    )}
	</svg>
    )
}
					
export function PromptAI({})
{
    const [allPrompts, setAllPrompts] = useState([]),
	  [searchFor, setSearchFor] = useState(''),
	  [searchResults, setSearchResults] = useState([]),
	  [activeIndex, setActiveIndex] = useState(0),
	  [fuse, setFuse] = useState(null),
	  [message, setMessage] = useState(''),
	  doSearch = () => {
	      if ( ! fuse ) return
	      console.log('searchFor: %O', searchFor)
	      if ( searchFor ) {
		  const results = fuse.search(searchFor)
		  setMessage(`${results.length} results found.`)
		  setSearchResults(prev => results.map(result => ({...result.item})))
		  setActiveIndex(0)
	      } else {
		  setMessage(`${allPrompts.length} results found.`)
		  setSearchResults(allPrompts)
	      }
	  },
	  onSelect = index => {
	      setActiveIndex(index)
	  }
    useEffect(() => {
	fetch('/assets/data/prompts.json')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		console.log('prompts = %O', json)
		const index = parseInt(Math.random()*json.length)
		console.log('activeIndex = %O', index)
		setAllPrompts(json)
		setMessage(`${json.length} results found.`)
		setSearchResults(json)
		setFuse(new Fuse(json, {keys: ['act', 'prompt']}))
	    })
	    .catch(r => {
		console.error('error in operation: %O, %O', r.status, r.statusText)
	    })
    }, [])
    useEffect(() => { // debouncer
	const handler = setTimeout(() => doSearch(), 500)
	return () => clearTimeout(handler)
    }, [searchFor])
    return (
	<>
	    <div class="mb-2 input-group">
		<input type="text"
		       class="form-control"
		       value={searchFor}
		       placeholder="search for prompts"
		       onChange={ev => setSearchFor(ev.target.value)}/>
		<button class="btn btn-outline-secondary"
			type="button"
			onClick={doSearch}><IconSearch/></button>
	    </div>
	    {message && <div class="form-text">{message}</div>}
	    <Carousel indicators
		      activeIndex={activeIndex}
		      onSelect={onSelect}>
		{console.log('results = %O', searchResults)}
		{searchResults.map((result, ri) => (
		    <Carousel.Item>
			<CarouselImage/>
			<Carousel.Caption>
			    <h3>{ri+1}. {result.act}</h3>
			    <p>{result.prompt}</p>
			</Carousel.Caption>
		    </Carousel.Item>
		))}
	    </Carousel>
	</>
    )
}
