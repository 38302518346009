import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const LinkToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a href=""
       ref={ref}
       onClick={(e) => {
	   e.preventDefault();
	   onClick(e);
       }}>
	{children}
	&#x25bc;
    </a>
))

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const DropSearchMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    return (
	<div ref={ref}
	     style={{backgroundColor: '#ddd', ...style}}
	     className={className}
	     aria-labelledby={labeledBy}>
	    <Form.Control autoFocus
			  className="mx-3 my-2 mb-3 w-75"
			  placeholder="Type to filter..."
			  onChange={(e) => setValue(e.target.value)}
			  value={value}/>
	    <ul className="list-unstyled mb-0 bg-white">
		{React.Children.toArray(children).filter((child) => (
		    !value || child.props.children.toLowerCase().startsWith(value)
		))}
	    </ul>
	</div>
    )
})
