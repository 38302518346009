
export function makeid(N)
{
    /* Started from: https://stackoverflow.com/a/19964557 */
    var firstchars = "abcdefghjkmnpqrstuvwxyz", allchars = "abcdefghjkmnpqrstuvwxyz23456789";
    let val = firstchars.charAt(Math.floor(Math.random()*firstchars.length)) + [...Array(N-1).keys()].map(n => allchars.charAt(Math.floor(Math.random()*allchars.length))).join('')
    return val;
}

const refresh_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcyMzExMTM3MSwiaWF0IjoxNzIwNTE5MzcxLCJqdGkiOiI2NTA5MWJlZjQyYWM0YzVjYWRkZTkyNzQ4M2UzZjljZiIsInVzZXJfaWQiOjV9.ZIm6c9umZnECRezd_OuT9nXxdDx-6LXRLpC0cisUmnQ"

export function get_access_token()
{
    return fetch('https://teramine.com/api/token/refresh/', {
	method: 'POST',
	body: JSON.stringify({'refresh': refresh_token}),
	headers: {
	    'Content-type': 'application/json'
	}
    }).then(r => r.ok ? r.json() : Promise.reject(r))
}

export function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}
