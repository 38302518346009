import * as React from "react";

const defaults = {
    height: '1.5rem',
    width: '1.5rem'
}

export function IconClose(props) {
    return (
	<svg fill="none" viewBox="0 0 24 24" {...defaults} {...props}>
	    <path
		fill="currentColor"
		d="M6.225 4.811a1 1 0 00-1.414 1.414L10.586 12 4.81 17.775a1 1 0 101.414 1.414L12 13.414l5.775 5.775a1 1 0 001.414-1.414L13.414 12l5.775-5.775a1 1 0 00-1.414-1.414L12 10.586 6.225 4.81z"
	    />
	</svg>
    );
}

export function IconDeleteOutline(props) {
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}
	>
	    <path d="M6 19a2 2 0 002 2h8a2 2 0 002-2V7H6v12M8 9h8v10H8V9m7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" />
	</svg>
    );
}

export function IconArrowsMove(props) {
    return (
	<svg
	    fill="currentColor"
	    viewBox="0 0 16 16"
	    {...defaults}
	    {...props}
	>
	    <path
		fillRule="evenodd"
		d="M7.646.146a.5.5 0 01.708 0l2 2a.5.5 0 01-.708.708L8.5 1.707V5.5a.5.5 0 01-1 0V1.707L6.354 2.854a.5.5 0 11-.708-.708l2-2zM8 10a.5.5 0 01.5.5v3.793l1.146-1.147a.5.5 0 01.708.708l-2 2a.5.5 0 01-.708 0l-2-2a.5.5 0 01.708-.708L7.5 14.293V10.5A.5.5 0 018 10zM.146 8.354a.5.5 0 010-.708l2-2a.5.5 0 11.708.708L1.707 7.5H5.5a.5.5 0 010 1H1.707l1.147 1.146a.5.5 0 01-.708.708l-2-2zM10 8a.5.5 0 01.5-.5h3.793l-1.147-1.146a.5.5 0 01.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L14.293 8.5H10.5A.5.5 0 0110 8z"
	    />
	</svg>
    );
}

export function IconBookmarkCheck(props) {
    return (
	<svg
	    fill="currentColor"
	    viewBox="0 0 16 16"
	    {...defaults}
	    {...props}
	>
	    <path
		fillRule="evenodd"
		d="M10.854 5.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.708 0l-1.5-1.5a.5.5 0 11.708-.708L7.5 7.793l2.646-2.647a.5.5 0 01.708 0z"
	    />
	    <path d="M2 2a2 2 0 012-2h8a2 2 0 012 2v13.5a.5.5 0 01-.777.416L8 13.101l-5.223 2.815A.5.5 0 012 15.5V2zm2-1a1 1 0 00-1 1v12.566l4.723-2.482a.5.5 0 01.554 0L13 14.566V2a1 1 0 00-1-1H4z" />
	</svg>
    );
}

export function IconSearch(props) {
    return (
	<svg
	    viewBox="0 0 1024 1024"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z" />
	</svg>
    );
}

export function IconOpenInNewWindow(props)
{
    return (
	<svg fill="none"
	     viewBox="0 0 15 15"
	     {...defaults}
	     {...props}>
	    <path
		fill="currentColor"
		fillRule="evenodd"
		d="M12 13a1 1 0 001-1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v3.5a.5.5 0 001 0V3h9v9H8.5a.5.5 0 000 1H12zM9 6.5v3a.5.5 0 01-1 0V7.707l-5.146 5.147a.5.5 0 01-.708-.708L7.293 7H5.5a.5.5 0 010-1h3a.498.498 0 01.5.497"
		clipRule="evenodd"
	    />
	</svg>
    );
}

export function IconBxChevronRight(props)
{
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
	</svg>
    );
}

export function IconBxChevronLeft(props)
{
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
	</svg>
    );
}

export function IconBxChevronUp(props)
{
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M6.293 13.293l1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z" />
	</svg>
    );
}

export function IconBxChevronDown(props)
{
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M16.293 9.293L12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
	</svg>
    );
}

export function IconHome(props)
{
    return (
	<svg
	    viewBox="0 0 1024 1024"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 00-44.4 0L77.5 505a63.9 63.9 0 00-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0018.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z" />
	</svg>
    );
}

export function IconBxsFileJson(props) {
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}
	>
	    <path d="M12.823 15.122c-.517 0-.816.491-.816 1.146 0 .661.311 1.126.82 1.126.517 0 .812-.49.812-1.146 0-.604-.291-1.126-.816-1.126z" />
	    <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6zM8.022 16.704c0 .961-.461 1.296-1.2 1.296-.176 0-.406-.029-.557-.08l.086-.615c.104.035.239.06.391.06.319 0 .52-.145.52-.67v-2.122h.761v2.131zm1.459 1.291c-.385 0-.766-.1-.955-.205l.155-.631c.204.105.521.211.846.211.35 0 .534-.146.534-.365 0-.211-.159-.331-.564-.476-.562-.195-.927-.506-.927-.996 0-.576.481-1.017 1.277-1.017.38 0 .659.08.861.171l-.172.615c-.135-.065-.375-.16-.705-.16s-.491.15-.491.325c0 .215.19.311.627.476.596.22.876.53.876 1.006.001.566-.436 1.046-1.362 1.046zm3.306.005c-1.001 0-1.586-.755-1.586-1.716 0-1.012.646-1.768 1.642-1.768 1.035 0 1.601.776 1.601 1.707C14.443 17.33 13.773 18 12.787 18zm4.947-.055h-.802l-.721-1.302a12.64 12.64 0 01-.585-1.19l-.016.005c.021.445.031.921.031 1.472v1.016h-.701v-3.373h.891l.701 1.236c.2.354.4.775.552 1.155h.014c-.05-.445-.065-.9-.065-1.406v-.985h.702v3.372zM14 9h-1V4l5 5h-4z" />
	</svg>
    );
}

export function IconDot(props) {
    return (
	<svg
	    viewBox="0 0 220 1000"
	    fill="currentColor"
	    {...defaults}
	    {...props}
	>
	    <path d="M110 390c30.667 0 56.667 10.667 78 32s32 47.333 32 78c0 29.333-10.667 55-32 77s-47.333 33-78 33-56.667-11-78-33-32-47.667-32-77c0-30.667 10.667-56.667 32-78s47.333-32 78-32" />
	</svg>
    );
}

export function IconBroom(props) {
    return (
	<svg
	    viewBox="0 0 24 24"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path d="M19.36 2.72l1.42 1.42-5.72 5.71c1.07 1.54 1.22 3.39.32 4.59L9.06 8.12c1.2-.9 3.05-.75 4.59.32l5.71-5.72M5.93 17.57c-2.01-2.01-3.24-4.41-3.58-6.65l4.88-2.09 7.44 7.44-2.09 4.88c-2.24-.34-4.64-1.57-6.65-3.58z" />
	</svg>
    );
}

export function IconCheckmarkCircleOutline(props) {
    return (
	<svg
	    viewBox="0 0 512 512"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path
		fill="none"
		stroke="currentColor"
		strokeMiterlimit={10}
		strokeWidth={32}
		d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
	    />
	    <path
		fill="none"
		stroke="currentColor"
		strokeLinecap="round"
		strokeLinejoin="round"
		strokeWidth={32}
		d="M352 176L217.6 336 160 272"
	    />
	</svg>
    );
}

export function Icon339CheckboxChecked(props) {
    return (
	<svg
	    viewBox="0 0 16 16"
	    fill="currentColor"
	    {...defaults}
	    {...props}>
	    <path
		fill="currentColor"
		d="M14 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM7 12.414L3.293 8.707l1.414-1.414L7 9.586l4.793-4.793 1.414 1.414L7 12.414z"
	    />
	</svg>
    );
}

// icon:check | Feathericons https://feathericons.com/ | Cole Bemis
export function IconCheck(props) {
    return (
	<svg
	    fill="none"
	    stroke="currentColor"
	    strokeLinecap="round"
	    strokeLinejoin="round"
	    strokeWidth={2}
	    viewBox="0 0 24 24"
	    {...defaults}
	    {...props}>
	    <path d="M20 6L9 17l-5-5" />
	</svg>
    );
}

// icon:eye | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
export function IconEye(props) {
    return (
	<svg
	    fill="currentColor"
	    viewBox="0 0 16 16"
	    {...defaults}
	    {...props}>
	    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 011.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 011.172 8z" />
	    <path d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z" />
	</svg>
    );
}
