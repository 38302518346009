import React, { useRef, useState, useEffect } from 'react';
import { getCookieValue } from './utils';
import { Modal, Button, Tab, Tabs, Accordion, Dropdown } from 'react-bootstrap';
import { LinkToggle, DropSearchMenu } from './dropdownwithmenu';
import { IconBxChevronRight, IconBxChevronLeft, IconOpenInNewWindow } from './icons'
import * as inputs from './inputs';
import { CreateForm } from './airtable/createrowform'
import { FormBuilder } from './airtable/formbuilder'

export function Airtable({setCost})
{
    const [bases, setBases] = useState([]),
	  [selectedBaseId, setSelectedBaseId] = useState(''),
	  [tablesInBase, setTablesInBase] = useState([]),
	  [selectedTableId, setSelectedTableId] = useState('')
    useEffect(() => {
	fetch('/api/airtable/base/')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		console.log('airtable bases: %O', json)
		setBases(json.bases)
	    })
	    .catch(r => {
		console.error('error in fetchtemplates: %O, %O', r.status, r.statusText)
	    })
    }, [])
    useEffect(() => {
	if ( ! selectedBaseId ) return
	setSelectedTableId('')
	setTablesInBase([])
	fetch(`/api/airtable/base/${selectedBaseId}/table/`)
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		console.log('tables in base %O: %O', selectedBaseId, json)
		setTablesInBase(json.tables)
	    })
	    .catch(r => {
		console.error('error in fetchtemplates: %O, %O', r.status, r.statusText)
	    })
    }, [selectedBaseId])
    return (
	<>
	    <div class="mt-4 row">
		<div class="col-6">
		    <div class="mb-2">
			<label class="form-label">Pick a base</label>
			{bases.length > 0 ? (
			    <select class="form-select"
				    value={selectedBaseId}
				    onChange={ev => setSelectedBaseId(ev.target.value)}>
				<option value="">-- Select --</option>
				{bases.map((base, bi) => <option key={bi} value={base.id}>{base.name}</option>)}
			    </select>
			) : (
			    <div class="ms-2 spinner-border spinner-border-sm text-primary" role="status">
				<span class="visually-hidden">Loading...</span>
			    </div>
			)}
		    </div>
		</div>
		<div class="col-6">
		    {tablesInBase.length > 0 && (
			<div class="mb-2">
			    <label class="form-label">Pick a table</label>
			    <select class="form-select"
				    value={selectedTableId}
				    onChange={ev => setSelectedTableId(ev.target.value)}>
				<option value="">-- Select --</option>
				{tablesInBase.map((table, ti) => <option value={table.id}>{table.name}</option>)}
			    </select>
			</div>
		    )}
		</div>
		<FormBuilder baseId={selectedBaseId}
			     tableId={selectedTableId}
			     tables={tablesInBase}/>
	    </div>
	</>
    )
}
