import React, { useRef, useState, useEffect } from 'react';
import { getCookieValue } from './utils';
import { Modal, Button, Tab, Tabs, Accordion, Dropdown } from 'react-bootstrap';
import { LinkToggle, DropSearchMenu } from './dropdownwithmenu';
import { IconBxChevronRight, IconBxChevronLeft, IconOpenInNewWindow } from './icons'
import dayjs from 'dayjs';

function TemplatesList({match, tmplSelected})
{
    return (
	<Dropdown>
	    <Dropdown.Toggle as={LinkToggle}>
		<span class="me-1">All Templates</span>
		<span style={{color: 'var(--bs-success)', fontSize: '0.8rem'}}>[{match.matches.length}]</span>
	    </Dropdown.Toggle>
	    <Dropdown.Menu as={DropSearchMenu}
			   style={{maxHeight: '400px',
				   overflowY: 'auto',
				   overflowX: 'hidden'}}>
		{match.matches.map((tform, ti) => (
		    <Dropdown.Item eventKey={ti+1}
				   onClick={ev => tmplSelected(tform.id)}>
			{tform.title}
		    </Dropdown.Item>
		))}		 
	    </Dropdown.Menu>
	</Dropdown>
    )
}

function AddNewFaq({currentTmpl, tmplSelected, setRefreshFaqs, setCost})
{
    const [question, setQuestion] = useState(''),
	  [answer, setAnswer] = useState(''),
	  [htmlAnswer, setHtmlAnswer] = useState(''),
	  [suggestedQs, setSuggestedQs] = useState([]),
	  [suggestion, setSuggestion] = useState(''),
	  [context, setContext] = useState('Try to use a business tone throughout'),
	  [canEditContext, setCanEditContext] = useState(false),
	  askQuestion = ev => {
	      const fd = new FormData()
	      fd.append('question', question)
	      fd.append('context', context)
	      setCost('')
	      fetch('/api/askaifaq/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('askaifaq: %O', json)
		      setAnswer(json.content)
		      setCost((Math.round(json.total_cost * 10000)/10000).toFixed(4) + ' &cent;')
		  })
		  .catch(r => {
		      console.error('error in askaifaq: %O, %O', r.status, r.statusText)
		  })
	  },
	  showMarkdown = tabName => {
	      if ( tabName !== 'preview' ) return
	      const fd = new FormData()
	      fd.append('markdown', answer)
	      fetch('/api/md2html/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('md2html: %O', json)
		      setHtmlAnswer(json.html)
		  })
		  .catch(r => {
		      console.error('error in askaifaq: %O, %O', r.status, r.statusText)
		  })
	  },
	  saveFaq = ev => {
	      const fd = new FormData()
	      fd.append('question', question)
	      fd.append('mdanswer', answer)
	      fetch(`/api/templatefaqs/${currentTmpl.slug}/`, {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('post templatefaq: %O', json)
		      tmplSelected(currentTmpl.id)
		      setRefreshFaqs(true)
		      setQuestion('')
		      setAnswer('')
		      setHtmlAnswer('')
		      setSuggestion('')
		  })
		  .catch(r => {
		      console.error('error in post templatefaq: %O, %O', r.status, r.statusText)
		  })
	  }
    useEffect(() => {
	setSuggestedQs([
	    `What is a ${currentTmpl.title}?`,
	    `What should a ${currentTmpl.title} contain?`,
	    `What are the benefits of using a ${currentTmpl.title}?`,
	    `What are the advantages of using a ${currentTmpl.title}?`,
	    `What are the disadvantages of using a ${currentTmpl.title}?`,
	    `Why should I use a ${currentTmpl.title}?`,
	])
    }, [currentTmpl])
    return (
	<>
	    <div class="mb-2">
		<label class="form-label">Context for question?</label>
		<div class="input-group">
		    <input type="text"
			   class="form-control"
			   value={context}
			   onChange={ev => setContext(ev.target.value)}
			   disabled={!canEditContext}/>
		    <button type="button"
			    class="btn btn-outline-info"
			    onClick={ev => setCanEditContext(!canEditContext)}>
			{canEditContext ? "Lock" : "Edit"}
		    </button>
		</div>
	    </div>
	    <div class="mb-2">
		<div class="d-flex justify-content-between align-items-center gap-3">
		    <label class="form-label">Question?</label>
		    <select class="form-select mb-2"
			    value={suggestion}
			    onChange={ev => {
				setSuggestion(ev.target.value)
				setQuestion(ev.target.value)
			    }}>
			<option value="">-- Suggested questions --</option>
			{suggestedQs.map(q => <option>{q}</option>)}
		    </select>
		</div>
		<div class="input-group">
		    <input type="text"
			   class="form-control"
			   value={question}
			   onChange={ev => setQuestion(ev.target.value)}/>
		    <button type="button"
			    class="btn btn-outline-info"
			    disabled={question.length == 0}
			    onClick={askQuestion}>Ask ChatGPT</button>
		</div>
	    </div>
	    <Tabs defaultActiveKey="markdown"
		  className="mb-2"
		  onSelect={showMarkdown}>
		<Tab eventKey="markdown" title="Answer">
		    <label class="form-label">Enter markdown here</label>
		    <textarea class="form-control mb-2"
			      rows="4"
			      value={answer}
			      onChange={ev => setAnswer(ev.target.value)}></textarea>
		    <div class="mb-2 text-end px-3">
			<button type="button"
				class="btn btn-primary"
				onClick={saveFaq}>Save</button>
		    </div>
		</Tab>
		<Tab eventKey="preview" title="Preview">
		    <div dangerouslySetInnerHTML={{__html: htmlAnswer}}></div>
		</Tab>
	    </Tabs>
	</>
    )
}

function ConfirmationDialog({isOpen, setIsOpen, title, body, okButtonText, onOK})
{
    return (
	<Modal show={isOpen} onHide={ev => setIsOpen(false)}>
	    <Modal.Header closeButton>
		<Modal.Title>{title}</Modal.Title>
	    </Modal.Header>
	    <Modal.Body>
		{body}
	    </Modal.Body>
	    <Modal.Footer>
		<Button variant="secondary" onClick={ev => setIsOpen(false)}>
		    Close
		</Button>
		<Button variant="primary"
			onClick={ev => {
			    onOK(ev).then(r => setIsOpen(false))
			}}>{okButtonText}</Button>
	    </Modal.Footer>
	</Modal>
    )
}

function EditFaqDialog({isOpen, setIsOpen, faqId, faqs})
{
    const [faq, setFaq] = useState(null),
	  [question, setQuestion] = useState(''),
	  [answer, setAnswer] = useState(''),
	  [htmlAnswer, setHtmlAnswer] = useState(''),
	  showMarkdown = tabName => {
	      if ( tabName !== 'preview' ) return
	      const fd = new FormData()
	      fd.append('markdown', answer)
	      fetch('/api/md2html/', {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('md2html: %O', json)
		      setHtmlAnswer(json.html)
		  })
		  .catch(r => {
		      console.error('error in askaifaq: %O, %O', r.status, r.statusText)
		  })
	  },
	  saveFaq = ev => {
	      const fd = new FormData()
	      fd.append('question', question)
	      fd.append('mdanswer', answer)
	      fetch(`/api/templatefaqs/${currentTmpl.slug}/`, {
		  method: 'POST',
		  body: fd,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('post templatefaq: %O', json)
		      tmplSelected(currentTmpl.id)
		      setRefreshFaqs(true)
		      setQuestion('')
		      setAnswer('')
		      setHtmlAnswer('')
		      setSuggestion('')
		  })
		  .catch(r => {
		      console.error('error in post templatefaq: %O, %O', r.status, r.statusText)
		  })
	  }
    useEffect(() => {
	const found = faqs.find(x => x.id === faqId)
	if ( found ) setFaq(found)
    }, [faqId, faqs])
    useEffect(() => {
	if ( faq ) {
	    setQuestion(faq.question)
	    const fd = new FormData()
	    fd.append('html', faq.answer)
	    console.log('convert html: %O', faq.answer)
	    fetch('/api/html2md/', {
		method: 'POST',
		body: fd,
		headers: {
                    'X-CSRFToken': getCookieValue('csrftoken'),
		}
	    })
		.then(r => r.ok ? r.json() : Promise.reject(r))
		.then(json => {
		    console.log('html2md: %O', json)
		    setAnswer(json.markdown)
		})
		.catch(r => {
		    console.error('error in post templatefaq: %O, %O', r.status, r.statusText)
		})
	}
    }, [faq])
    return (
	<Modal show={isOpen} onHide={ev => setIsOpen(false)}>
	    <Modal.Header closeButton>
		<Modal.Title>Edit FAQ</Modal.Title>
	    </Modal.Header>
	    <Modal.Body>
		<div class="mb-2">
		    <label class="form-label">Question?</label>
		    <input type="text"
			   class="form-control"
			   value={question}
			   onChange={ev => setQuestion(ev.target.value)}/>
		</div>
		<Tabs defaultActiveKey="markdown"
		      className="mb-2"
		      onSelect={showMarkdown}>
		    <Tab eventKey="markdown" title="Answer">
			<label class="form-label">Enter markdown here</label>
			<textarea class="form-control mb-2"
				  rows="4"
				  value={answer}
				  onChange={ev => setAnswer(ev.target.value)}></textarea>
		    </Tab>
		    <Tab eventKey="preview" title="Preview">
			<div dangerouslySetInnerHTML={{__html: htmlAnswer}}></div>
		    </Tab>
		</Tabs>
	    </Modal.Body>
	    <Modal.Footer>
		<Button variant="secondary" onClick={ev => setIsOpen(false)}>
		    Close
		</Button>
		<Button variant="primary"
			onClick={ev => setIsOpen(false)}>Save</Button>
	    </Modal.Footer>
	</Modal>
    )
}

function TemplateFaqTab({frmTemplates, selectedTmpl, tmplSelected, setCost})
{
    const [deleteFaqId, setDeleteFaqId] = useState(0),
	  [editFaqId, setEditFaqId] = useState(0),
	  [tmplFaqs, setTmplFaqs] = useState([]),
	  [tmplFaqsGeneric, setTmplFaqsGeneric] = useState([]),
	  [isOpen, setIsOpen] = useState(true),
	  [refreshFaqs, setRefreshFaqs] = useState(true),
	  deleteFaq = faqId => {
	      return fetch(`/api/templatefaqs/${selectedTmpl.slug}/${faqId}/`, {
		  method: 'DELETE',
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('delete faq response: %O', json)
		      tmplSelected(selectedTmpl.id)
		  })
		  .catch(r => {
		      console.error('error in delete faq: %O, %O', r.status, r.statusText)
		  })
	  }
    useEffect(() => {
	if ( selectedTmpl ) {
	    fetch(`/api/templatefaqs/${selectedTmpl.slug}`)
		.then(r => r.ok ? r.json() : Promise.reject(r))
		.then(json => {
		    setTmplFaqs(json[0].matches)
		    setTmplFaqsGeneric(json[1].matches)
		    setRefreshFaqs(false)
		})
		.catch(r => {
		    console.error('error in templatefaqs: %O, %O', r.status, r.statusText)
		})
	}
    }, [selectedTmpl, refreshFaqs])
    return (
	<>
	    <Accordion>
		{tmplFaqs.map((tmplfaq, fi) => (
		    <Accordion.Item eventKey={fi}>
			<Accordion.Header>{tmplfaq.question}</Accordion.Header>
			<Accordion.Body>
			    <div dangerouslySetInnerHTML={{__html: tmplfaq.answer}}></div>
			    <div class="border-top pt-2 d-flex justify-content-between">
				<button type="button"
					class="btn btn-outline-info"
					onClick={ev => {
					    setEditFaqId(tmplfaq.id)
					    setIsOpen(true)
					}}>Edit</button>
				<button type="button"
					class="btn btn-outline-danger"
					onClick={ev => {
					    setDeleteFaqId(tmplfaq.id)
					    setIsOpen(true)
					}}>Delete</button>
			    </div>
			</Accordion.Body>
		    </Accordion.Item>
		))}
		<Accordion.Item eventKey={tmplFaqs.length}>
		    <Accordion.Header>Add a new FAQ</Accordion.Header>
		    <Accordion.Body><AddNewFaq currentTmpl={selectedTmpl}
					       tmplSelected={tmplSelected}
					       setRefreshFaqs={setRefreshFaqs}
					       setCost={setCost}/></Accordion.Body>
		</Accordion.Item>
		{deleteFaqId > 0 && <ConfirmationDialog isOpen={isOpen}
							setIsOpen={setIsOpen}
							title="Delete Confirmation"
							body="Are you sure you want to delete this FAQ?"
							okButtonText="Delete"
							onOK={ev => {
							    return deleteFaq(deleteFaqId)
								.then(r => {
								    setDeleteFaqId(0)
								    setRefreshFaqs(true)
								})
							}}/>}
		{editFaqId > 0 && <EditFaqDialog isOpen={isOpen}
						 setIsOpen={setIsOpen}
						 faqId={editFaqId}
						 faqs={tmplFaqs}/>}
	    </Accordion>
	</>
    )
}

function YoutubeTab({selectedTmpl})
{
    const [iframeUrl, setIframeUrl] = useState('about:blank'),
	  [thumbText, setThumbText] = useState(selectedTmpl.title),
	  [bgImageUrl, setBgImageUrl] = useState(`https://teramine.com/assets/template/${selectedTmpl.slug}/0.jpg`)
    useEffect(() => {
	const params = new URLSearchParams()
	params.append('fieldLabel', thumbText)
	params.append('bgImage', bgImageUrl)
	console.log('params = "%O"', params.toString())
	setIframeUrl("https://teramine.com/x/bare/?" + params.toString())
    }, [selectedTmpl, thumbText])
    return (
	<>
	    <h3 class="form-label">Youtube Thumbnail Generator</h3>
	    <div class="d-flex justify-content-between align-items-center gap-3">
		<div class="w-100">
		    <div class="mb-2">
			<label class="form-label">Text</label>
			<input type="text"
			       class="form-control"
			       value={thumbText}
			       onChange={ev => setThumbText(ev.target.value)}/>
		    </div>
		    <div class="mb-2">
			<label class="form-label">Background Image</label>
			<input type="text"
			       class="form-control"
			       value={bgImageUrl}
			       onChange={ev => setBgImageUrl(ev.target.value)}/>
		    </div>
		</div>
		<img src={bgImageUrl} width="168" height="94"/>
	    </div>
	    <div class="w-100"
		 style={{width: '100%', height: '500px', overflowX: 'auto', overflowY: 'auto'}}>
		<iframe src={iframeUrl}
			height="720"
			width="1280"></iframe>
	    </div>
	</>
    )
}

function TemplateTabs({frmTemplates, selectedTmpl, tmplSelected, setCost})
{
    return (
	<Tabs defaultActiveKey="faqs"
	      className="mb-2">
	    <Tab eventKey="faqs" title="FAQs">
		<TemplateFaqTab frmTemplates={frmTemplates}
				selectedTmpl={selectedTmpl}
				tmplSelected={tmplSelected}
				setCost={setCost}/>
	    </Tab>
	    <Tab eventKey="youtube" title="Youtube">
		<YoutubeTab selectedTmpl={selectedTmpl}/>
	    </Tab>
	</Tabs>
    )
}

export function Templates({setCost})
{
    const [frmTemplates, setFrmTemplates] = useState([]),
	  [selectedTmpl, setSelectedTmpl] = useState(null),
	  [nextIndex, setNextIndex] = useState(-1), // this is not an ID but an index
	  [prevIndex, setPrevIndex] = useState(-1), // this is not an ID but an index
	  loadPrevTmpl = ev => {
	      const prev = frmTemplates[0].matches[prevIndex]
	      tmplSelected(prev.id)
	  },
	  loadNextTmpl = ev => {
	      const next = frmTemplates[0].matches[nextIndex]
	      tmplSelected(next.id)
	  },
	  tmplSelected = tmplId => {
	      const found = frmTemplates[0].matches.find(x => x.id === tmplId)
	      setSelectedTmpl(found)
	  }
    useEffect(() => {
	fetch('/api/templates/')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(json => {
		json.results[0].matches.sort(x => x.id)
		console.log('fetchtemplates response: %O', json.results)
		setFrmTemplates(json.results)
		const selectedTmplId = window.localStorage.getItem('selectedTmplId')
		if ( selectedTmplId ) {
		    const found = json.results[0].matches.find(x => x.id === +selectedTmplId)
		    if ( found ) setSelectedTmpl(found)
		}
	    })
	    .catch(r => {
		console.error('error in fetchtemplates: %O, %O', r.status, r.statusText)
	    })
    }, [])
    useEffect(() => {
	console.log('useEffect(): selectedTmpl changed: %O', selectedTmpl)
	if ( selectedTmpl ) {
	    window.localStorage.setItem('selectedTmplId', selectedTmpl.id)
	    const index = frmTemplates[0].matches.findIndex(x => x.id === selectedTmpl.id)
	    if ( index === frmTemplates[0].matches.length - 1 ) {
		setNextIndex(0)
		setPrevIndex(index - 1)
	    } else if ( index === 0 ) {
		setNextIndex(index + 1)
		setPrevIndex(frmTemplates[0].matches.length - 1)
	    } else {
		setNextIndex(index + 1)
		setPrevIndex(index - 1)
	    }
	}
    }, [selectedTmpl])
    return (
	<>
	    {frmTemplates.length > 0 ? (
		<div class="mb-2">
		    {frmTemplates.map((match, mi) => <TemplatesList match={match}
								    tmplSelected={tmplSelected}/>)}
		</div>
	    ) : (
		<div class="spinner-border text-primary" role="status">
		    <span class="visually-hidden">Loading...</span>
		</div>
	    )}
	    {selectedTmpl && (
		<>
		    <div class="d-flex justify-content-between align-items-center">
			<IconBxChevronLeft height="3rem"
					   width="3rem"
					   style={{cursor: 'pointer'}}
					   onClick={ev => loadPrevTmpl()}/>
			<div class="d-flex gap-3 align-items-center">
			    <span>[{selectedTmpl.id}.{selectedTmpl.srcform.frmid}]</span>
			    <h2 class="text-center">
				<a href={`https://teramine.com/templates/${selectedTmpl.slug}/`}
				   class="text-decoration-none"
				   target="_blank">
				    {selectedTmpl.title}
				    <IconOpenInNewWindow class="ms-2"/>
				</a>
			    </h2>
			</div>
			<IconBxChevronRight height="3rem"
					    width="3rem"
					    style={{cursor: 'pointer'}}
					    onClick={ev => loadNextTmpl()}/>
		    </div>
		    <TemplateTabs frmTemplates={frmTemplates}
				  selectedTmpl={selectedTmpl}
				  tmplSelected={tmplSelected}
				  setCost={setCost}/>
		    </>
	    )}
	</>
    )
}
