import { makeid } from './utils';

export function textinput(question)
{
    return {
	"type": "TextInput",
	"icon": "edit_square",
	"title": "Single Line Text",
	"btnColor": "darkcyan",
	"fieldLabel": question['question'],
	"fieldDescr": '',
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "singleLineText",
		"dataType": "string",
		"label": question.question
	    }
	]
    }
}

export function multilineinput(question)
{
    return {
	"type": "MultilineInput",
	"icon": "edit_note",
	"title": "Multiline Text",
	"btnColor": "darksalmon",
	"fieldLabel": question['question'],
	"fieldDescr": "",
	"tooltipHeader": "Enter requested text",
	"tooltipBody": "<p>Please complete the form and click Next to continue.</p>",
	"valueRequired": true,
	"minLength": 0,
	"maxLength": null,
	"visibleRows": 4,
	"maxAllowed": 100000,
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "multiLineText",
		"dataType": "string",
		"label": question.question
	    }
	]
    }
}

export function addressinput(question)
{
    return {
	"type": "AddressInput",
	"icon": "home",
	"title": "Address Input",
	"btnColor": "#CC3399",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Enter address",
	"tooltipBody": "<p>Enter your address and click Next to continue.</p>",
	"valueRequired": true,
	"lineOneLabel": "Street Address",
	"lineTwoLabel": "Street Address Line 2",
	"cityLabel": "City",
	"stateLabel": "State / Province",
	"countryLabel": "Country",
	"zipLabel": "Postal / Zip code",
	"needLineTwo": true,
	"restrictCountries": false,
	"restrictTo": [],
	"displayStr": question.question,
	"stepId": "pq652ets",
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": "xrkvab78",
		"type": "singleLineText",
		"name": "lineone",
		"dataType": "string",
		"label": `${question.question}-Street Address`
	    },
	    {
		"id": "vcehjkg4",
		"type": "singleLineText",
		"name": "linetwo",
		"dataType": "string",
		"label": `${question.question}-Street Address Line 2`
	    },
	    {
		"id": "wzc9gk4z",
		"type": "singleLineText",
		"name": "city",
		"dataType": "string",
		"label": `${question.question}-City`
	    },
	    {
		"id": "z84rbnwp",
		"type": "singleLineText",
		"name": "state",
		"dataType": "string",
		"label": `${question.question}-State / Province`
	    },
	    {
		"id": "bfyhmtzm",
		"type": "singleLineText",
		"name": "country",
		"dataType": "string",
		"label": `${question.question}-Country`
	    },
	    {
		"id": "mk82k54c",
		"type": "singleLineText",
		"name": "zip",
		"dataType": "string",
		"label": `${question.question}-Postal / Zip code`
	    }
	]
    }
}

export function numberinput(question)
{
    return {
	"type": "NumberInput",
	"icon": "looks_two",
	"title": "Number Input",
	"btnColor": "tomato",
	"fieldLabel": question['question'],
	"fieldDescr": "<p>Enter a number or use the spinner</p>",
	"tooltipHeader": "Number required",
	"tooltipBody": "<p>Please enter a number.</p>",
	"valueRequired": true,
	"minValue": "",
	"maxValue": "",
	"stepIncrement": 1,
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "number",
		"dataType": "number",
		"label": question.question
	    }
	]
    }
}

export function contactinput(question)
{
    return {
	"type": "ContactInput",
	"icon": "contact_phone",
	"title": "Contact Input",
	"btnColor": "#CC6600",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Enter contact information",
	"tooltipBody": "<p>Enter contact name, email and phone number.</p>",
	"valueRequired": true,
	"nameRequired": false,
	"nameLabel": "Contact name",
	"phoneLabel": "Phone number",
	"emailLabel": "Email address",
	"extraRequired": "none",
	"phoneConsentRequired": true,
	"phoneConsentText": "Do you consent to us using your phone number for marketing purposes?",
	"emailConsentRequired": true,
	"emailConsentText": "Can we send you marketing messages at this email address?",
	"smsConsentRequired": true,
	"smsConsentText": "Do you consent to receiving marketing messages by SMS?",
	"requiredMethod": "both",
	"restrictCountries": false,
	"restrictTo": [],
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": null,
	"dataFields": [
	    {
		"id": makeid(8),
		"name": "name",
		"type": "text",
		"dataType": "string",
		"label": `${question.question}-name`
	    },
	    {
		"id": makeid(8),
		"name": "phone",
		"type": "phone",
		"dataType": "string",
		"label": `${question.question}-phone`
	    },
	    {
		"id": makeid(8),
		"name": "email",
		"type": "email",
		"dataType": "string",
		"label": `${question.question}-email`
	    },
	    {
		"id": makeid(8),
		"name": "preferred-method",
		"type": "text",
		"dataType": "string",
		"label": `${question.question}-preferred-method`
	    },
	    {
		"id": makeid(8),
		"name": "phone-consent",
		"type": "checkbox",
		"dataType": "bool",
		"label": `${question.question}-phone-consent`
	    },
	    {
		"id": makeid(8),
		"name": "sms-consent",
		"type": "checkbox",
		"dataType": "bool",
		"label": `${question.question}-sms-consent`
	    },
	    {
		"id": makeid(8),
		"name": "email-consent",
		"type": "checkbox",
		"dataType": "bool",
		"label": `${question.question}-email-consent`
	    }
	]
    }
}

export function singlechoice(question)
{
    return {
	"type": "SingleChoice",
	"icon": "radio_button_checked",
	"title": "Single Choice",
	"btnColor": "darkviolet",
	"fieldLabel": question['question'],
	"fieldDescr": "",
	"tooltipHeader": "Select an option",
	"tooltipBody": "<p>Select an option from the choices shown and click Next to continue.</p>",
	"valueRequired": true,
	"numColumns": 1,
	"choices": question['options'],
	"otherOption": "none",
	"otherOptionValue": "Other",
	"otherInput": {
	    "label": "please specify",
	    "numRows": 1,
	    "numCols": 40,
	    "inputId": makeid(8)
	},
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": null,
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "singleChoice",
		"dataType": "enum",
		"label": question.question
	    }
	]
    }
}

export function numberchoice(question)
{
    return {
	"type": "NumberChoice",
	"icon": "pin",
	"title": "Number Choice",
	"template": "BoxChoice",
	"btnColor": "slateblue",
	"fieldLabel": question['question'],
	"fieldDescr": "<p>Pick a number from the choices shown.</p>",
	"tooltipHeader": "Select an option",
	"tooltipBody": "<p>Select an option from the choices shown and click Next to continue.</p>",
	"valueRequired": true,
	"allowMultiSelection": false,
	"showAllowMultiSelection": true,
	"width": 50,
	"height": 50,
	"gap": 10,
	"numRows": 3,
	"isSquare": true,
	"arrangement": "row",
	"boxColor": "#FFFFFF",
	"startFrom": 1,
	"stepInterval": 1,
	"numValues": 9,
	"choices": question['options'],
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "choice",
		"dataType": "enumlist",
		"label": question.question
	    }
	]
    }
}

export function multichoice(question)
{
    return {
	"type": "MultiChoice",
	"icon": "check_box",
	"title": "Multi Choice",
	"btnColor": "darkslateblue",
	"fieldLabel": question['question'],
	"fieldDescr": "<p>Select one or more options from the list below.</p>",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please choose one or more options and click Next to continue.</p>",
	"valueRequired": true,
	"numColumns": 1,
	"choices": question['options'],
	"otherOption": "none",
	"otherOptionValue": "Other",
	"otherInput": {
	    "label": "please specify",
	    "numRows": 1,
	    "numCols": 40,
	    "inputId": makeid(8)
	},
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": null,
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "multipleChoice",
		"dataType": "enumlist",
		"label": question.question
	    }
	]
    }
}

export function dropdowninput(question)
{
    return {
	"type": "DropdownInput",
	"icon": "lists",
	"title": "Dropdown",
	"btnColor": "peru",
	"fieldLabel": question['question'],
	"fieldDescr": "<p>Use the dropdown to make your choice.</p>",
	"tooltipHeader": "Select an option",
	"tooltipBody": "<p>Select an option from the choices shown and click Next to continue.</p>",
	"valueRequired": true,
	"displayStr": question['question'],
	"stepId": makeid(8),
	"allowMultiSelection": false,
	"choices": question['options'],
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "singleChoice",
		"dataType": "enumlist",
		"label": question.question
	    }
	]
    }
}

export function yesnochoice(question)
{
    return {
	"type": "YesNoChoice",
	"icon": "rule",
	"title": "Yes/No Choice",
	"template": "BoxChoice",
	"btnColor": "steelblue",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Select an option",
	"tooltipBody": "<p>Select an option from the choices shown and click Next to continue.</p>",
	"valueRequired": true,
	"allowMultiSelection": false,
	"showAllowMultiSelection": false,
	"width": 150,
	"height": 40,
	"gap": 10,
	"numRows": 3,
	"isSquare": true,
	"arrangement": "row",
	"boxColor": "#FFFFFF",
	"needThirdOption": false,
	"thirdOptionText": "Maybe",
	"choices": [
	    "Yes",
	    "No",
	],
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "choice",
		"dataType": "enumlist",
		"label": question.question
	    }
	]
    }
}

export function boxchoice(question)
{
    return {
	"type": "BoxChoice",
	"icon": "widgets",
	"title": "Boxed Choice",
	"btnColor": "saddlebrown",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Select an option",
	"tooltipBody": "<p>Select an option from the choices shown and click Next to continue.</p>",
	"valueRequired": true,
	"allowMultiSelection": false,
	"showAllowMultiSelection": true,
	"width": 100,
	"height": 50,
	"gap": 10,
	"numRows": 3,
	"isSquare": false,
	"arrangement": "row",
	"boxColor": "#FFFFFF",
	"choices": question.options,
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "choice",
		"dataType": "enumlist",
		"label": "Please choose an option"
	    }
	]
    }
}

export function dateinput(question)
{
    return {
	"type": "DateInput",
	"icon": "calendar_month",
	"title": "Date Input",
	"btnColor": "firebrick",
	"fieldLabel": question['question'],
	"fieldDescr": "<p>Click above to enter the date.</p>",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"showInline": true,
	"disableDates": null,
	"altInput": false,
	"altFormat": "F j, Y",
	"dateFormat": "Y-m-d",
	"timeFormat": "H:i:S",
	"timePickerIncr": 5,
	"startTime": "09:00",
	"endTime": "18:00",
	"startFromToday": false,
	"range": false,
	"timeRequired": false,
	"monthOnly": false,
	"displayStr": question['question'],
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "dateTime",
		"dataType": "datetime",
		"label": question.question
	    }
	]
    }
}

export function datedropdown(question)
{
    return {
	"type": "DateDropdown",
	"icon": "date_range",
	"title": "Date Dropdown",
	"btnColor": "teal",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Select date",
	"tooltipBody": "<p>Select date from the dropdown.</p>",
	"valueRequired": true,
	"dayLabel": "Day",
	"monthLabel": "Month",
	"yearLabel": "Year",
	"yearStart": 1950,
	"numYears": 150,
	"monthNames": [
	    "January",
	    "February",
	    "March",
	    "April",
	    "May",
	    "June",
	    "July",
	    "August",
	    "September",
	    "October",
	    "November",
	    "December"
	],
	"dateFormats": [
	    {
		"value": "MM/DD/YYYY",
		"label": "01/03/1982"
	    },
	    {
		"value": "MMM-DD-YYYY",
		"label": "Jan-07-1982"
	    },
	    {
		"value": "MMMM DD YYYY",
		"label": "January 07 1982"
	    },
	    {
		"value": "M/D/YYYY",
		"label": "1/7/1982"
	    },
	    {
		"value": "MMM D YYYY",
		"label": "Jan 7 1982"
	    },
	    {
		"value": "MMMM D YYYY",
		"label": "January 7 1982"
	    },
	    {
		"value": "DD/MM/YYYY",
		"label": "07/01/1982"
	    },
	    {
		"value": "DD-MMM-YYYY",
		"label": "07-Jan-1982"
	    },
	    {
		"value": "DD MMMM YYYY",
		"label": "07 January 1982"
	    },
	    {
		"value": "D/M/YYYY",
		"label": "7/1/1982"
	    },
	    {
		"value": "D MMM YYYY",
		"label": "7 Jan 1982"
	    },
	    {
		"value": "D MMMM YYYY",
		"label": "7 January 1982"
	    }
	],
	"selectedDateFormat": {
	    "value": "MMM-DD-YYYY",
	    "label": "Jan-07-1982"
	},
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"name": "day",
		"type": "singleSelect",
		"dataType": "integer",
		"label": `${question.question}-Day`
	    },
	    {
		"id": makeid(8),
		"name": "month",
		"type": "singleSelect",
		"dataType": "string",
		"label": `${question.question}-Month`
	    },
	    {
		"id": makeid(8),
		"name": "year",
		"type": "singleSelect",
		"dataType": "integer",
		"label": `${question.question}-Year`
	    },
	    {
		"id": makeid(8),
		"name": "date",
		"type": "date",
		"dataType": "string",
		"label": question.question
	    }
	]
    }
}

export function phoneinput(question)
{
    return {
	"type": "PhoneInput",
	"icon": "call",
	"title": "Phone Number",
	"btnColor": "forestgreen",
	"fieldLabel": question.question,
	"fieldDescr": "<p>Hit Enter to continue.</p>",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"restrictCountries": false,
	"restrictTo": [],
	"displayStr": question.question,
	"stepId": makeid(8),
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "phone",
		"dataType": "string",
		"label": question.question
	    }
	]
    }
}

export function emailinput(question)
{
    return {
	"type": "EmailInput",
	"icon": "contact_mail",
	"title": "Email Address",
	"btnColor": "darkkhaki",
	"fieldLabel": question.question,
	"fieldDescr": "<p>A valid email address is required.</p>",
	"tooltipHeader": "Enter your email address",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"validationRequired": false,
	"consentRequired": false,
	"consentText": "I consent to receive marketing messages at this email address",
	"privacyPolicyLink": "",
	"displayStr": question.question,
	"stepId": makeid(8),
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "email",
		"dataType": "string",
		"label": question.question
	    },
	    {
		"id": makeid(8),
		"type": "checkbox",
		"dataType": "bool",
		"label": "I consent to receive marketing messages at this email address"
	    },
	    {
		"id": makeid(8),
		"type": "checkbox",
		"dataType": "bool",
		"label": "validated"
	    }
	]
    }
}

export function nameinput(question)
{
    return {
	"type": "NameInput",
	"icon": "person",
	"title": "Name Input",
	"btnColor": "olive",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"displayStr": question.question,
	"stepId": makeid(8),
	"firstNameLabel": "First",
	"middleNameLabel": "Middle",
	"needMiddleNameInput": false,
	"lastNameLabel": "Last",
	"requireFirstName": true,
	"requireLastName": true,
	"firstNameSize": 10,
	"middleNameSize": 5,
	"lastNameSize": 20,
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "singleLineText",
		"name": "firstname",
		"dataType": "string",
		"label": `${question.question}-First`
	    },
	    {
		"id": makeid(8),
		"type": "singleLineText",
		"name": "middlename",
		"dataType": "string",
		"label": `${question.question}-Middle`
	    },
	    {
		"id": makeid(8),
		"type": "singleLineText",
		"name": "lastname",
		"dataType": "string",
		"label": `${question.question}-Last`
	    }
	]
    }
}

export function fileupload(question)
{
    return {
	"type": "FileUpload",
	"icon": "upload_file",
	"title": "File Upload",
	"btnColor": "darkturquoise",
	"fieldLabel": question.question,
	"fieldDescr": "<p>Click to upload a file from your computer.</p>",
	"tooltipHeader": "File Upload",
	"tooltipBody": "<p>Please choose a file from your computer to upload.</p>",
	"minFilesRequired": 1,
	"maxFilesRequired": 1,
	"fileType": "",
	"displayStr": question.question,
	"stepId": makeid(8),
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "fileUpload",
		"dataType": "file",
		"label": question.question
	    }
	]
    }
}

export function countryselect(question)
{
    return {
	"type": "CountrySelect",
	"icon": "public",
	"title": "Country Region",
	"btnColor": "indigo",
	"fieldLabel": question.question,
	"fieldDescr": "<p>Select the country from the dropdown.</p>",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"countryLabel": "Country",
	"stateLabel": "State / Province",
	"stateRequired": true,
	"restrictCountries": false,
	"restrictTo": [],
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"name": "country",
		"type": "singleSelect",
		"dataType": "enum",
		"label": `${question.question}-Country`
	    },
	    {
		"id": makeid(8),
		"name": "state",
		"type": "singleSelect",
		"dataType": "enum",
		"label": `${question.question}-State / Province`
	    }
	]
    }
}

export function apptinput(question)
{
    return {
	"type": "ApptInput",
	"icon": "calendar_month",
	"title": "Appointment Input",
	"btnColor": "#0066CC",
	"fieldLabel": question.question,
	"fieldDescr": "<p>Select a date and pick the desired appointment time.</p>",
	"tooltipHeader": "Select date and time",
	"tooltipBody": "<p>Allows you to pick an appointment date and time.</p>",
	"valueRequired": true,
	"showInline": true,
	"dateFormat": "Y-m-d",
	"timePickerIncr": 30,
	"startTime": "00:00",
	"endTime": "23:00",
	"startFromToday": true,
	"width": 100,
	"height": 50,
	"gap": 10,
	"numCols": 3,
	"numRows": 6,
	"isSquare": false,
	"arrangement": "column",
	"boxColor": "#FFFFFF",
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"choices": [
	    "00:00",
	    "00:30",
	    "01:00",
	    "01:30",
	    "02:00",
	    "02:30",
	    "03:00",
	    "03:30",
	    "04:00",
	    "04:30",
	    "05:00",
	    "05:30",
	    "06:00",
	    "06:30",
	    "07:00",
	    "07:30",
	    "08:00",
	    "08:30",
	    "09:00",
	    "09:30",
	    "10:00",
	    "10:30",
	    "11:00",
	    "11:30",
	    "12:00",
	    "12:30",
	    "13:00",
	    "13:30",
	    "14:00",
	    "14:30",
	    "15:00",
	    "15:30",
	    "16:00",
	    "16:30",
	    "17:00",
	    "17:30",
	    "18:00",
	    "18:30",
	    "19:00",
	    "19:30",
	    "20:00",
	    "20:30",
	    "21:00",
	    "21:30",
	    "22:00",
	    "22:30"
	],
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "date",
		"dataType": "date",
		"label": `${question.question}-date`
	    },
	    {
		"id": makeid(8),
		"type": "choice",
		"dataType": "enumlist",
		"label": `${question.question}-time`
	    }
	]
    }
}

export function rangeslider(question)
{
    return {
	"type": "RangeSlider",
	"icon": "sliders",
	"title": "Range Slider",
	"btnColor": "#9966FF",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Range Slider",
	"tooltipBody": "<p>Move the slider back and forth to change the value.<br>Pick a suitable value.</p>",
	"valueRequired": true,
	"minValue": 0,
	"maxValue": 100,
	"stepValue": 1,
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "number",
		"dataType": "number",
		"label": question.question
	    }
	]
    }
}

export function currencyinput(question)
{
    return {
	"type": "CurrencyInput",
	"icon": "attach_money",
	"title": "Currency Input",
	"btnColor": "mediumseagreen",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Enter the requested information",
	"tooltipBody": "<p>Please complete the form and hit Enter or click Next to continue.</p>",
	"valueRequired": true,
	"restrictCurrencies": false,
	"restrictTo": [],
	"minAmount": null,
	"maxAmount": null,
	"allowDecimals": true,
	"displayStr": question.question,
	"stepId": makeid(8),
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "currency",
		"dataType": "float",
		"label": `${question.question}-amount`,
	    },
	    {
		"id": makeid(8),
		"type": "dropdown",
		"dataType": "enum",
		"label": `${question.question}-currencyCode`
	    }
	]
    }
}

export function signatureinput(question)
{
    return {
	"type": "SignatureInput",
	"icon": "stylus_note",
	"title": "Signature Input",
	"btnColor": "#339933",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Signature Input",
	"tooltipBody": "<p>Use the pencil to draw your signature.<br>Or use the text button to type it in a font that you like.<br>Click <em>Update</em> when you are satisfied.</p>",
	"valueRequired": true,
	"xPadding": 10,
	"yPadding": 5,
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": [
	    {
		"id": makeid(8),
		"type": "image",
		"dataType": "string",
		"label": question.question
	    }
	]
    }
}

export function choicematrix(question)
{
    const rowLabels = 'rowLabels' in question ? question.rowLabels : [
	"Location",
	"Content",
	"Price"
    ]
    return {
	"type": "ChoiceMatrix",
	"icon": "table",
	"title": "Choice Matrix",
	"btnColor": "#996633",
	"fieldLabel": question.question,
	"fieldDescr": "",
	"tooltipHeader": "Choose options from the table",
	"tooltipBody": "<p>Make one or more selections from the table.</p>",
	"valueRequired": true,
	"allowMultiSelection": false,
	"rowLabels": rowLabels,
	"columnLabels": question.options,
	"displayStr": question.question,
	"stepId": makeid(8),
	"logic": {
	    "exitPage": []
	},
	"dataFields": rowLabels.map(rowLabel => ({
	    "id": makeid(8),
	    "label": `${question.question}-${rowLabel}`,
	    "type": "multiChoice",
	    "dataType": "enum"
	}))
    }
}

export function companyinput(question)
{
    return {
	"icon": "source_environment",
	"type": "CompanyInput",
	"logic": null,
	"title": "Company Input",
	"stepId": makeid(8),
	"btnColor": "#FF5050",
	"industry": [
	    "Technology",
	    "Healthcare",
	    "Finance and Insurance",
	    "Manufacturing",
	    "Retail and Consumer Goods",
	    "Energy and Utilities",
	    "Construction and Real Estate",
	    "Transportation and Logistics",
	    "Education",
	    "Entertainment and Media"
	],
	"nameLabel": "Company name",
	"sizeLabel": "Size of the company",
	"dataFields": [
	    {
		"id": "cg3qx5u5",
		"name": "company",
		"type": "text",
		"label": `${question.question}-company`,
		"dataType": "string"
	    },
	    {
		"id": "nhv6nnnx",
		"name": "domain",
		"type": "text",
		"label": `${question.question}-domain`,
		"dataType": "string"
	    },
	    {
		"id": "etc2crta",
		"name": "jobfunction",
		"type": "text",
		"label": `${question.question}-jobfunction`,
		"dataType": "string"
	    },
	    {
		"id": "p2bteraq",
		"name": "jobtitle",
		"type": "text",
		"label": `${question.question}-jobtitle`,
		"dataType": "string"
	    },
	    {
		"id": "rc2qrd9g",
		"name": "companySize",
		"type": "singleChoice",
		"label": `${question.question}-companySize`,
		"dataType": "enum"
	    },
	    {
		"id": "q5hr8yzv",
		"name": "industry",
		"type": "singleChoice",
		"label": `${question.question}-industry`,
		"dataType": "enum"
	    }
	],
	"displayStr": question.question,
	"fieldDescr": "",
	"fieldLabel": question.question,
	"domainLabel": "Company domain name",
	"sizeChoices": [
	    "Less than 10",
	    "10 to 100",
	    "100 to 1000",
	    "Over 1000 employees"
	],
	"tooltipBody": "<p>Enter company details such as company name, website domain, industry, company size, etc.</p>",
	"industryLabel": "Company industry or sector",
	"jobtitleLabel": "Your job title",
	"numColumnsInd": 2,
	"tooltipHeader": "Enter company details",
	"valueRequired": true,
	"domainRequired": true,
	"numColumnsSize": 3,
	"jobfunctionLabel": "Your job function",
	"jobtitleRequired": true,
	"jobfunctionRequired": true
    }
}
