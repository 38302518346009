import React, { useRef, useState, useEffect } from 'react';
import { getCookieValue } from './utils';
import { IconSearch } from './icons';

export function SearchTemplates({})
{
    const [textValue, setTextValue] = useState(''),
	  [editContent, setEditContent] = useState(''),
	  [searchCounts, setSearchCounts] = useState([]),
	  [showCountSpinner, setShowCountSpinner] = useState(false),
	  askCounts = () => {
	      setSearchCounts([])
	      setShowCountSpinner(true)
	      const fdsearch = new FormData()
	      fdsearch.append('title', textValue)
	      fetch('/api/askcounts/', {
		  method: 'POST',
		  body: fdsearch,
		  headers: {
                      'X-CSRFToken': getCookieValue('csrftoken'),
		  }
	      })
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      console.log('askcounts response: %O', json)
		      setSearchCounts(json.results)
		      setEditContent(json.results[0].matches.map((match, mi) => `${mi+1}. [${match.title}](https://teramine.com/templates/${match.slug}/)`).join('\n'))
		      setShowCountSpinner(false)
		  })
		  .catch(r => {
		      console.error('error in askcounts: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		      setShowCountSpinner(false)
		  })
	  }
    return (
	<>
	    <div class="mb-2">
		<div class="input-group">
		    <input type="text"
			   class="form-control"
			   placeholder='enter form slug e.g. "business registration form"'
			   value={textValue}
			   onChange={ev => setTextValue(ev.target.value)}/>
		    <button type="button"
			    class="btn btn-outline-secondary"
			    disabled={!textValue || showCountSpinner}
			    onClick={askCounts}>
			{showCountSpinner ?
			 <div class="spinner-border spinner-border-sm text-primary"></div> :
			 <IconSearch/>}
		    </button>
		</div>
	    </div>
	    <div class="mb-2">
		<textarea class="form-control"
			  style={{fontFamily: 'monospace', height: 'calc(80vh - 50px)'}}
			  value={editContent}
			  onChange={ev => setEditContent(ev.target.value)}></textarea>
	    </div>
	</>
    )
}
